import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from '@views/public/home/home.component';

const routes: Routes = [
  { path: '',
    component: HomeComponent,
    loadChildren: () => import('@views/public/public.module').then((m) => m.PublicModule)
  },
  {
    path: '',
    loadChildren: () => import('@views/features/features.module').then(x => x.FeaturesModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
