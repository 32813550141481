import { Injectable, inject } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@core/services/auth.service';
import { environment } from '@env/environment';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem(environment.authToken) || '';
    if (token) {
      request = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${JSON.parse(token)}`)
      });
    }
    return next.handle(request);
  }
}
